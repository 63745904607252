@use 'src/assets/styles/base/buttons';
@use 'src/assets/styles/abstracts/utils';

.button {
  @include buttons.button-base;
  @include buttons.button-icon;

  &--primary {
    @include buttons.button-primary;
  }

  &--secondary {
    @include buttons.button-secondary;
  }

  &--dark {
    @include buttons.button-dark;
  }

  &--text-link {
    @include buttons.button-text-link;
  }

  &--text-link-dark {
    @include buttons.button-text-link-dark;
  }

  &--text-link-secondary {
    @include buttons.button-text-link-secondary;
  }

  &--primary#{&}--i-left,
  &--secondary#{&}--i-left {
    padding-left: utils.toRem(12px);
  }

  &--primary#{&}--i-right,
  &--secondary#{&}--i-right {
    padding-right: utils.toRem(12px);
  }

  &--primary#{&}--i-center,
  &--secondary#{&}--i-center {
    padding: 0 utils.toRem(16px);
  }
}
