@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/base/animations';
@use 'src/assets/styles/base/form-layout';
@use 'src/assets/styles/base/typekit';

.calculator-modals {
  &__text {
    @include typekit.font-body-1;
    color: colors.$surface-color-active;

    &--bold {
      font-weight: 700;
    }

    &--low-emphasis {
      color: colors.$surface-color-inactive-med;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(24px);
    padding: utils.toRem(4px);
  }

  &__spinner {
    @include animations.spinner-rotate-left-animation;
  }

  &__disclaimer {
    @include typekit.font-subtitle-1-bold;
    color: colors.$danger-color-700;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: utils.toRem(8px);
  }

  &__disclaimer-icon {
    height: utils.toRem(24px);
    width: utils.toRem(24px);
    fill: colors.$danger-color-800;
    padding: 0;
  }

  &__max-calculations-wrapper {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(32px);
  }

  &__calculations-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);
  }

  &__checkbox-label {
    display: flex;
    flex-direction: column;
  }

  &__checkbox-title {
    @include typekit.font-subtitle-2-bold;
  }

  &__checkbox-description {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-inactive-med;
  }

  &__checkbox-details {
    flex-direction: column;
    align-items: start;
    justify-content: center;

    @include breakpoints.query('>=md') {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &__checkbox-divider {
    display: none;

    @include breakpoints.query('>=md') {
      display: block;
      width: utils.toRem(1px);
      height: utils.toRem(14px);
      background-color: colors.$surface-color-inactive-low;
      margin: 0 utils.toRem(10px);
    }
  }

  &__checkbox-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: utils.toRem(48px);
    height: utils.toRem(48px);
    border-radius: utils.toRem(100px);
    padding: utils.toRem(8px);
    background-color: colors.$surface-color-active;
    color: colors.$white;
  }

  &__checkbox-icon {
    width: utils.toRem(28px);
    height: utils.toRem(28px);
  }
}