@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.saved-calculations {
  @include layout.container-md;
  padding-block: utils.toRem(48px);
  display: flex;
  flex-direction: column;
  gap: utils.toRem(48px);

  &__loading {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50vh;
  }

  &__back-button {
    border: none;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: utils.toRem(32px);
  }

  &__title {
    @include typekit.font-display-4;
    color: colors.$surface-color-active;
  }

  &__subtitle {
    @include typekit.font-body-1;
    color: colors.$surface-color-inactive-med;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);
  }

  &__load-more {
    width: 100%;
  }

  &__list-container {
    max-width: utils.toRem(648px);
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);
  }
}