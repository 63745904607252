@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/table';
@use 'src/assets/styles/base/typekit';

.table {
  @include table.default;

  @include breakpoints.query('>=md') {
    margin-top: utils.toRem(8px);
  }

  &__bottom-header {
    @include typekit.font-body-2-bold;
  }
}

.description-list {
  @include typekit.font-body-2-bold;
  background-color: colors.$surface-color-variant;
  padding-block: utils.toRem(16px);
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include breakpoints.query('>=md') {
    background-color: colors.$white;
    border: variables.$standard-border;
  }

  &__label {
    padding-left: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      padding-left: utils.toRem(32px);
    }
  }

  &__value {
    padding-right: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      padding-right: utils.toRem(32px);
    }
  }
}

.results {
  @include layout.container-md;
  display: flex;
  flex-direction: column;
  gap: utils.toRem(32px);
  padding-block: utils.toRem(48px);

  &__buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      flex-direction: row;
      justify-content: space-between;
      gap: 0;
    }
  }

  &__back-button {
    border: none;
  }

  &__actions-wrapper {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      flex-direction: row;
    }
  }

  &__wrapper {
    color: colors.$surface-color-active;
    background-color: colors.$white;
    display: flex;
    flex-direction: column;
    gap: utils.toRem(48px);
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    gap: utils.toRem(8px);
  }

  &__title {
    @include typekit.font-display-5;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);

    @include breakpoints.query('>=md') {
      @include typekit.font-display-4;
    }
  }

  &__edit-button {
    border-radius: 100%;
    width: utils.toRem(40px);
    height: utils.toRem(40px);
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
  }

  &__sub-text {
    @include typekit.font-body-1;
    color: colors.$surface-color-active;
  }

  &__tooltip-wrapper {
    display: flex;
    align-items: center;
  }

  &__tooltip-icon {
    width: utils.toRem(13px);
    height: utils.toRem(13px);
    fill: colors.$surface-color-inactive-med;
  }

  &__tooltip {
    text-transform: none;
  }

  &__table-title {
    @include typekit.font-subtitle-1;
    display: block;
  }

  &__table-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: utils.toRem(16px);
  }

  &__table-wrapper {
    overflow: auto;
  }

  &__table-actions {
    display: flex;
    gap: utils.toRem(8px);
  }

  &__chart {
    display: flex;
    align-items: start;
    padding-top: utils.toRem(24px);
    flex-direction: column;
    gap: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      flex-direction: row;
      padding-top: utils.toRem(32px);
      gap: utils.toRem(72px);
      align-items: center;
    }
  }

  &__chart-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: utils.toRem(24px) 0;

    @include breakpoints.query('>=md') {
      width: fit-content;
      padding: 0;
    }
  }

  &__chart-wrapper {
    max-width: utils.toRem(280px);

    @include breakpoints.query('>=md') {
      max-width: utils.toRem(300px);
    }
  }

  &__chart-legend {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    gap: utils.toRem(16px);
    width: fit-content;
  }

  &__chart-legend-item {
    display: flex;
    flex-direction: row;
    gap: utils.toRem(16px);
    justify-content: center;
    align-items: center;
  }

  &__chart-legend-indicator {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
  }

  &__chart-legend-title {
    @include typekit.font-subtitle-1;
  }

  &__chart-title {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);
    margin-bottom: utils.toRem(32px);
  }

  &__chart-title-text {
    @include typekit.font-display-6;
  }

  &__chart-title-amount {
    @include typekit.font-display-4;
  }

  &__steps-wrapper {
    padding: utils.toRem(16px) utils.toRem(16px) 0;

    @include breakpoints.query('>=md') {
      padding: 0;
    }
  }

  &__steps-form {
    width: 100%;
    max-width: utils.toRem(1134px);
  }

  &__context-menu {
    width: 100%;
  }
}
