/* COLORS */
$primary-color-400: #de0043; // primary, primary on surface, & hyperlink
$primary-color-900: #443247; // primary variant
$primary-color-200: #ff7596; // on snackbar

$secondary-color-1000: #092b3e;
$secondary-color-900: #10384f; // secondary
$secondary-color-700: #006f9b; // secondary on surface
$secondary-color-400: #00bcff; // secondary variant
$secondary-color-200: #a4e7ff; // on snackbar variant

$danger-color-800: #974401; // danger variant
$danger-color-700: #b85302; // danger, danger on surface
$danger-color-400: #f9780d; // danger variant
$danger-color-surface: #ffe4cd; // danger surface

$white: #ffffff;
$black: #000000;
$anciliary-color-100: #89d329;
$success-green: #0ea01a;
$skeleton-mask: #e2e7ea;
$filter-border-pressed: #f7bcce;
$snackbar-success: #333333;

// States
$primary-color-state-01: #fef5f7;
$primary-color-state-02: #fbe0e8;
$primary-color-state-03: #fad6e1;
$primary-color-state-04: #e9527f;
$primary-color-state-05: #e63d70;
$primary-color-state-enabled: #f29eb8;
$primary-color-state-disabled: #f8cbd9;

$disabled-color: #9e9e9e;
$disabled-color-stroke: #e0e0e0;
$secondary-color-state: #d1f3ff;
$secondary-color-state-01: #edfaff;
$secondary-color-state-02: #e0f7ff;
$secondary-color-state-03: #a4e7ff;
$loading-color-state: #1b4f6d;
$dark-theme-color-state-01: #0a4e6d;
$danger-state-disabled: rgba(255, 255, 255, 0.24);

// On Surface
$surface-color-active: #10384f; // High emphasis
$surface-color-inactive-med: #587484; // Medium Emphasis
$surface-color-inactive-low: #a4b3bc; // Low Emphasis
$surface-color-overlay: #00000029;
$surface-color-stroke: #e2e7ea;
$surface-color-base: #10384f; // Opaque
$surface-color-variant: #f5f5f5;
$surface-color-disabled: #10384f61;

// Pills
$success-color-100: #d3ffd7;
$success-color-900: #053709;
$error-color-100: #ffd6d6;
$error-color-900: #730b0b;
