@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.calculator-widget {
  padding: utils.toRem(16px);
  overflow: hidden;
  background-color: colors.$white;

  @include breakpoints.query('>=md') {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: utils.toRem(24px);
    border: variables.$standard-border;
  }

  &__text-wrapper {

    @include breakpoints.query('>=md') {
      width: 50%;
    }

    @include breakpoints.query('>=lg') {
      width: 100%;
    }

    &--no-retreat {
      width: 100%;

      @include breakpoints.query('>=md') {
        width: 50%;
      }
    }
  }

  &__heading {
    @include typekit.font-subtitle-1-bold;
    margin-bottom: utils.toRem(16px);
    color: colors.$surface-color-active;

    @include breakpoints.query('>=md') {
      @include typekit.font-display-6;
    }
  }

  &__description {
    @include typekit.font-body-2;
    margin-bottom: utils.toRem(24px);
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=md') {
      @include typekit.font-body-1;
      margin-bottom: utils.toRem(32px);
    }
  }

  &__list {
    margin-bottom: utils.toRem(32px);
  }

  &__list-item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: utils.toRem(16px);
    }
  }

  &__icon-wrapper {
    display: inline-block;
    margin-right: utils.toRem(16px);
  }

  &__icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$secondary-color-700;
  }

  &__icon-text {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
    }
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto utils.toRem(32px);
    max-width: utils.toRem(400px);
    padding-left: utils.toRem(25px);
    padding-right: utils.toRem(25px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(0px);
      padding: utils.toRem(0px);
      width: 50%;
    }

    @include breakpoints.query('>xl') {
      padding: utils.toRem(0px);
      width: 50%;
    }
  }

  &__image {
    width: 100%;
  }

  &__button {
    width: 100%;

    @include breakpoints.query('>=md') {
      width: auto;
    }
  }
}