@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.all-programs {
  width: 100%;

  &__wrapper {
    @include layout.container-md;
    width: 100%;
  }

  &__hero {
    padding-block: utils.toRem(48px) utils.toRem(24px);
  }

  &__heading {
    @include typekit.font-display-5;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);

    @include breakpoints.query('>=md') {
      @include typekit.font-display-4;
      margin-bottom: utils.toRem(16px);
    }
  }

  &__sub-heading {
    @include typekit.font-body-1;
    color: colors.$surface-color-inactive-med;
  }

  &__empty-state-wrapper {
    max-width: utils.toRem(632px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: utils.toRem(48px) 0;
  }
}