@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/abstracts/utils';

.summary-card {
  display: flex;
  align-items: start;
  justify-content: space-between;
  border: variables.$standard-border;
  padding: utils.toRem(16px);

  @include breakpoints.query('>=md') {
    align-items: center;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: utils.toRem(16px);
    }
  }

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: utils.toRem(48px);
    height: utils.toRem(48px);
    border-radius: utils.toRem(100px);
    padding: utils.toRem(8px);
    background-color: colors.$surface-color-active;
    color: colors.$white;
  }

  &__icon {
    width: utils.toRem(28px);
    height: utils.toRem(28px);
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include typekit.font-subtitle-2-bold;
  }

  &__title-button {
    text-align: left;
    color: colors.$surface-color-active;

    &:not([disabled]) {
      &:hover {
        text-decoration: underline;
        color: colors.$surface-color-inactive-med;
        cursor: pointer;
      }
    }
  }

  &__description {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-inactive-med;
  }

  &__details {
    flex-direction: column;
    align-items: start;
    justify-content: center;

    @include breakpoints.query('>=md') {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &__divider {
    display: none;

    @include breakpoints.query('>=md') {
      display: block;
      width: utils.toRem(1px);
      height: utils.toRem(14px);
      background-color: colors.$surface-color-inactive-low;
      margin: 0 utils.toRem(10px);
    }
  }

}