@use '../abstracts/colors';
@use '../abstracts/utils';
@use './typekit';

@mixin button-base {
  display: inline-block;
  padding: utils.toRem(10px) utils.toRem(16px);
  border-width: 1px;
  border-style: solid;
  text-align: center;
  outline: none;
  transition: color 300ms ease-in-out, background-color 300ms ease-in-out,
    border-color 300ms ease-in-out;

  &:hover {
    cursor: pointer;
  }
}

@mixin button-primary {
  @include typekit.font-button;
  color: colors.$white;
  background-color: colors.$primary-color-400;
  border-color: colors.$primary-color-400;

  &:hover {
    color: colors.$primary-color-400;
    background-color: colors.$primary-color-state-01;
    border-color: colors.$surface-color-stroke;
  }

  &:focus {
    color: colors.$white;
    background-color: colors.$primary-color-state-05;
    border-color: colors.$primary-color-state-05;
  }

  &:active {
    color: colors.$white;
    background-color: colors.$primary-color-state-04;
    border-color: colors.$primary-color-state-04;
  }

  &:disabled {
    color: colors.$surface-color-inactive-low;
    background-color: colors.$surface-color-stroke;
    border-color: colors.$surface-color-stroke;
  }
}

@mixin button-secondary {
  @include typekit.font-button;
  color: colors.$primary-color-400;
  background-color: colors.$white;
  border-color: colors.$surface-color-stroke;

  &:hover {
    color: colors.$primary-color-400;
    background-color: colors.$primary-color-state-01;
    border-color: colors.$surface-color-stroke;
  }

  &:focus {
    color: colors.$primary-color-400;
    background-color: colors.$primary-color-state-02;
    border-color: colors.$surface-color-stroke;
  }

  &:active {
    color: colors.$primary-color-400;
    background-color: colors.$primary-color-state-03;
    border-color: colors.$surface-color-stroke;
  }

  &:disabled {
    color: colors.$surface-color-inactive-low;
    background-color: colors.$white;
    border-color: colors.$surface-color-stroke;
  }
}

@mixin button-dark {
  @include typekit.font-button;
  color: colors.$white;
  background-color: colors.$surface-color-active;
  border-color: colors.$surface-color-stroke;

  &:hover {
    color: colors.$surface-color-active;
    background-color: colors.$primary-color-state-01;
    border-color: colors.$surface-color-stroke;
  }

  &:focus {
    color: colors.$white;
    background-color: colors.$surface-color-inactive-med;
    border-color: colors.$surface-color-inactive-med;
  }

  &:active {
    color: colors.$white;
    background-color: colors.$surface-color-inactive-med;
    border-color: colors.$surface-color-inactive-med;
  }

  &:disabled {
    color: colors.$surface-color-inactive-low;
    background-color: colors.$surface-color-stroke;
    border-color: colors.$surface-color-stroke;
  }
}

@mixin button-text-link {
  padding: 2px;
  border: none;
  color: colors.$primary-color-400;
  background-color: transparent;
  border: 1px solid transparent;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    background-color: colors.$primary-color-state-01;
    border-color: colors.$primary-color-state-01;
  }

  &:focus {
    text-decoration: none;
    background-color: colors.$primary-color-state-01;
    border-color: colors.$primary-color-400;
  }

  &:active {
    text-decoration: none;
    background-color: colors.$primary-color-state-03;
    border-color: colors.$primary-color-state-03;
  }

  &:disabled {
    color: colors.$surface-color-inactive-low;
    background-color: transparent;
    border-color: transparent;
    text-decoration: underline;
  }
}

@mixin button-text-link-dark {
  @include button-text-link;
  color: colors.$white;

  &:hover {
    background-color: colors.$dark-theme-color-state-01;
    border-color: transparent;
  }

  &:focus {
    background-color: colors.$dark-theme-color-state-01;
    border-color: colors.$white;
  }

  &:active {
    background-color: colors.$secondary-color-700;
    border-color: transparent;
  }

  &:disabled {
    color: colors.$disabled-color-stroke;
    background-color: transparent;
    border-color: transparent;
    text-decoration: underline;
  }
}

@mixin button-text-link-secondary {
  @include button-text-link;
  color: colors.$secondary-color-700;

  &:hover {
    background-color: colors.$secondary-color-state-01;
    border-color: transparent;
  }

  &:focus {
    background-color: colors.$secondary-color-state-01;
    border-color: colors.$secondary-color-700;
  }

  &:active {
    background-color: colors.$secondary-color-700;
    border-color: transparent;
  }

  &:disabled {
    color: colors.$disabled-color-stroke;
    background-color: transparent;
    border-color: transparent;
    text-decoration: underline;
  }
}

@mixin button-icon {
  &--i-left,
  &--i-right {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &--i-left {
    flex-direction: row-reverse;
  }

  &__icon {
    flex: 0 0 auto;
    width: utils.toRem(18px);
    height: utils.toRem(18px);
    margin-top: -1px;
    margin-bottom: -1px;
    fill: currentColor;

    &--left {
      margin-right: utils.toRem(8px);
    }

    &--right {
      margin-left: utils.toRem(8px);
    }
  }
}

@mixin nav-link($active-modifier: null) {
  background-color: colors.$white;
  position: relative;
  cursor: pointer;
  transition: background-color 300ms ease-in-out;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 4px solid transparent;
    transition: border 300ms ease-in-out;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: colors.$surface-color-variant;
  }

  @if ($active-modifier) {
    &#{$active-modifier} {
      background-color: colors.$surface-color-variant;

      &::before {
        border-width: 0;
        border-bottom-width: utils.toRem(4px);
        border-bottom-color: colors.$secondary-color-700;
      }
    }
  }

  &:active {
    &::before {
      border-width: 1px;
      border-color: colors.$surface-color-active;
    }
  }

  &:focus {
    outline: none;

    &::before {
      border-width: utils.toRem(4px);
      border-color: colors.$secondary-color-400;
    }
  }
}
