@use 'src/assets/styles/base/animations';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.context-menu {
  position: relative;
  display: inline-block;

  &__modal {
    position: absolute;
    background: colors.$white;
    box-shadow: 0px utils.toRem(1px) utils.toRem(5px) 0px rgba(0, 0, 0, 0.2);
    min-width: utils.toRem(150px);
    z-index: 1000;

    &--icon-right {
      transform: translateX(20%);
    }

    &--icon-left {
      transform: translateX(-95%);
    }

    &--cta-right {
      left: 0;
    }

    &--cta-left {
      right: 0;
    }

    &:focus {
      outline: none;
    }
  }

  &__item {
    @include typekit.font-subtitle-1;
    cursor: pointer;
    color: colors.$surface-color-active;
    border-bottom: variables.$standard-border;
    padding: utils.toRem(12px) utils.toRem(16px);
    text-align: left;
    width: 100%;
    outline: none;
    display: block;

    &:hover,
    &:focus,
    &--selected {
      background: colors.$surface-color-overlay;
    }
  }

  &__button {
    color: colors.$surface-color-active;
    border-radius: 100%;
    padding: utils.toRem(8px);
    width: utils.toRem(40px);
    height: utils.toRem(40px);
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;

    &:not([disabled]) {
      cursor: pointer;

      &:hover,
      &:focus,
      &:focus-within {
        background: colors.$surface-color-overlay;
      }
    }
  }

  &__button-loading {
    @include animations.spinner-rotate-left-animation;
  }
}