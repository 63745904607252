@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.bayer-rewards {
  display: flex;
  flex-direction: column;
  background-color: colors.$white;

  @include breakpoints.query('>=md') {
    border: variables.$standard-border;
  }

  @include breakpoints.query('>=lg') {
    height: 100%;
  }

  &__card-link {
    border-bottom: variables.$standard-border;
  }

  &__years {
    padding: 0 utils.toRem(16px);
  }

  &__years-inner {
    padding: utils.toRem(16px) 0;
    border-bottom: variables.$standard-border;
  }

  &__empty-state {
    height: 100%;
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: utils.toRem(16px);
    border-top: variables.$standard-border;
  }

  &__icon {
    fill: colors.$surface-color-inactive-med;
    width: utils.toRem(16px);
    height: utils.toRem(16px);
    margin-right: utils.toRem(8px);
  }

  &__footer-text {
    color: colors.$surface-color-inactive-med;
  }
}