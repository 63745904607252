@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';


.info-banner {
  width: 100%;

  &--informational {
    border: solid 1px colors.$surface-color-stroke;
    background-color: colors.$secondary-color-state-01;
    color: colors.$surface-color-active;
  }

  &--danger {
    border: 0;
    background-color: colors.$danger-color-surface;
    color: colors.$danger-color-800;
  }

  &__padding {
    padding: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      &--content {
        padding: utils.toRem(24px) utils.toRem(16px);
      }
    }
  }

  &__line-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__icon-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__icon-left {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    margin-right: utils.toRem(8px);
    flex-shrink: 0;


    &--informational {
      fill: colors.$surface-color-active;
    }

    &--danger {
      fill: colors.$danger-color-800;
    }
  }

  &__title {
    @include typekit.font-subtitle-2-bold;
    white-space: pre-wrap;
  }

  &__close-button {
    cursor: pointer;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    padding: utils.toRem(5px);
    background: none;
    border: none;
    fill: colors.$primary-color-400;
    outline: 4px solid transparent;
    transition: outline-color 300ms ease-in-out;

    &:focus {
      outline-color: colors.$secondary-color-400;
    }
  }

  &__children {
    margin: utils.toRem(16px) 0;

    @include breakpoints.query('>=md') {
      padding-left: utils.toRem(36px);
    }
  }

  &__content-text {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;
    margin-bottom: 0;
  }
}